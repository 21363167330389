/* eslint-disable prefer-destructuring */
import { mapActions, mapGetters } from 'vuex';
import { Sidebar, Advertisement, Loader } from '@/components';
import isServer from '@/utils/isServer';
import _ from 'lodash';
import moment from 'moment';

if (!isServer) {
  const { required } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');
  var VueTagsInput = require('@johmun/vue-tags-input').default;
  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('dates', {
    message: 'De einddatum van de activiteit kan niet kleiner zijn dan de begindatum.',
    params: ['target'],
    validate(value, { target }) {
      return value < target;
    },
  });
  var ImageUpload = require('vue-image-crop-upload').default;
}

export default {
  name: 'AdvertisementForm',
  components: {
    [Sidebar.name]: Sidebar,
    [Advertisement.name]: Advertisement,
    [Loader.name]: Loader,
    VueTagsInput,
    ImageUpload,
  },
  props: {},
  data: () => ({
    editMode: false,
    error: false,
    show: false,
    imageObj: '',
    currentTag: '',
    tags: [],
    advertisementId: null,
    advertisement: {
      name: '',
      from: '',
      until: '',
      link: '',
      tags: [],
      picture: '',
    },
    showGifUpload: false,
  }),
  computed: {
    ...mapGetters('advertisement', {
      existingAdvertisement: 'getAdvertisement',
      isLoading: 'getLoadingState',
      buttonIsLoading: 'getButtonLoadingState',
    }),
    ...mapGetters('tag', {
      tagsList: 'getTagList',
    }),
    filteredItems() {
      return this.tagsList.filter(
        (i) => i.text.toLowerCase().indexOf(this.currentTag.toLowerCase()) !== -1,
      );
    },
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('tag', {
      initialiseTags: 'FETCH_DATA_TAG_LIST',
    }),
    ...mapActions('advertisement', {
      initialiseAdvertisement: 'FETCH_DATA_ADVERTISEMENT',
      createNewAdvertisementAction: 'CREATE_NEW_ADVERTISEMENT',
      updateAdvertisementAction: 'UPDATE_ADVERTISEMENT',
    }),
    toggleGifInput() {
      this.showGifUpload = !this.showGifUpload;
    },
    gifSuccess(FileInputevent) {
      const file = FileInputevent.target.files[0];
      this.advertisement.picture = file;
      this.createImage(this.advertisement.picture);
    },
    cropSuccess(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          var extensionType = blob.type.split('/')[1];
          const file = new File([blob], 'image', { type: `image/${extensionType}` });
          this.advertisement.picture = file;
        })
        .then(() => {
          this.createImage(this.advertisement.picture);
          this.toggleShow();
        });
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    toggleShow() {
      this.show = !this.show;
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        }
        if (this.editMode) {
          this.updateAdvertisement();
        } else {
          this.createNewAdvertisement();
        }
      });
    },
    createNewAdvertisement() {
      this.createNewAdvertisementAction(this.advertisement)
        .then(() => {
          this.$router.push({ name: 'advertisements' });
        })
        .catch((error) => {
          console.log('error', error);
          this.$toasted.show(...error.response.data);
        });
    },
    updateAdvertisement() {
      this.updateAdvertisementAction(this.advertisement)
        .then(() => {
          this.$router.push({ name: 'advertisements' });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    updateUntilDate() {
      this.advertisement.until = moment(this.advertisement.from).add(1, 'days').toISOString();
    },
    tagsChanged(tags) {
      this.tags = tags.map((t) => ({ text: t.text }));
      this.advertisement.tags = this.tags;
    },
    isAdmin() {
      return !!(this.$isAdmin || this.club.currentUserIsAdmin);
    },
    cancel() {
      this.$router.push({ name: 'advertisements' });
    },
  },
  async created() {
    await this.initialiseTags();

    // Only when editing an existing Event
    if (this.$route.params.advertisementId) {
      this.editMode = true;
      if (this.$route.params.duplicate) {
        this.editMode = false;
      }
      this.advertisementId = this.$route.params.advertisementId;
      await this.initialiseAdvertisement(this.advertisementId)
        .then(() => {
          this.advertisement = _.clone(this.existingAdvertisement);
          this.imageObj = _.cloneDeep(this.existingAdvertisement.picture);

          // Set existing tags:
          this.tags = this.advertisement.advertisementTags.map((t) => ({ text: t.name }));
          this.advertisement.tags = this.tags; // Update/Create model requires this format.
        });
    }
  },
};
